<template>
    <!-- 欧派抓取 -->
    <!-- <div v-loading="loading" element-loading-text="登录中..." element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.6)" class="login-container"> -->

    <div class="list-two">
      <el-table :data="tableData" border style="width: 100%" max-height="600" size="small" stripe tooltip-effect="dark">
        <el-table-column fixed prop="id" label="id" width="160" class-name="custom-header">
        </el-table-column>
        <el-table-column prop="name" label="角色名称" width="140">
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间" width="120">
        </el-table-column>
        <el-table-column prop="updateByName" label="最后修改人" width="140">
        </el-table-column>
        <el-table-column prop="updateDate" label="最后修改时间" width="120" >
        </el-table-column>
        <el-table-column prop="remarks" label="备注">
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
    <el-pagination
    background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      :total="total">
    </el-pagination>

    </div>

<!-- loading -->
  <!-- </div> -->

  </template>


 <!-- <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleDelete(scope.row)" size="mini" type="danger">删除任务</el-button>
          </template>

          70  390  410 400  420
        </el-table-column> -->
    
    
  <script>
    // 可以忽略eslint检查未使用变量错误
    // eslint-disable-next-line no-unused-vars
    // ... 这里是你的代码 ...
  </script>
    
    
    <script>

    import { deleteTask,getRolePageList} from '../../api/user'
    
    export default {
      data() {
        return {
          loading: true, // 初始化为 true，显示 loading 等待框
          tableData: [],
          dialogVisible: false,
          row: {}, // 传进来的行数据
          total: 0, // 总记录数
          pageNum: 1, // 当前页码
          pageSize: 10, // 每页显示的记录数
        }
      },
    
      methods: {

        handleSizeChange(size) {
          this.pageSize = size;
          this.getList(); // 根据当前设置重新获取数据
        },
        handleCurrentChange(page) {
          this.pageNum = page;
          this.getList(); // 根据当前设置重新获取数据
        },

        // 欧派第三方数据测试
        getList() {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            // searchStr: '', // 搜索关键字
            total:0,
            platformType: 'MTDS', // 平台类型
          };
          console.log(params,"params")
          getRolePageList(params).then((res) => {
            let data=res.data.data.data.records

            // 处理时间戳，转换为日期格式
            data.forEach(record => {
              record.createDate = this.$options.filters.formatDate(record.createDate);
              record.updateDate = this.$options.filters.formatDate(record.updateDate);
            });

              console.log(data, "data");
              this.tableData = data;
              // 总页数
              this.total = res.data.data.data.total;            
          }).catch((err) => { // eslint-disable-line no-unused-vars
            console.log(err)
            console.log('error submit!! 报错')
            this.loading = false
          })
        },

        //删除任务
      handleDelete(row) {
        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteTask(row.id).then((res) => { // eslint-disable-line no-unused-vars
            this.loading = true;
            this.$message.success("删除成功");
            this.getList();
            this.loading = false
          }).catch((err) => { // eslint-disable-line no-unused-vars
  
            console.log('error submit!! 报错');
            this.loading = false
            return false
  
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      },
    
      },

      created() {
        this.getList()
      }
      
    };
    </script>
    
  
  <style>
  .el-table thead {
    color: #1f2225;
  }
  </style>
    
  <style lang="scss" scoped>
  .el-main {
    padding: 0 !important;
  }
  </style>